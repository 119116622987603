<script setup>
import { useSearch } from "@/composables/useSearch";
import { useApp } from "~/composables/useApp";

const {
  searchVerses,
  filter,
  resultsColl,
  resultsBooksColl,
  loading,
  setFilter,
} = useSearch();

const { bibles, appVars } = useApp();

//

const loadingRef = ref(false);

const searchValue = ref("");
const searchWholeWord = ref(false);
const searchWithRegister = ref(false);
const searchPlace = ref("all");

const searchPlaceColl = [
  { label: "The whole Bible", value: "all" },
  { label: "Old testament", value: "ot" },
  { label: "New testament", value: "nt" },
];

watchEffect(() =>
  setFilter({
    bookPlace: searchPlace.value,
  })
);

function handleSearch() {
  if (!searchValue.value.trim()) return;

  const searchParams = {
    bibleCode: bibles.singleBible.location.bibleModel.bibleCode,
    searchValue: searchValue.value,
    wholeWord: searchWholeWord.value,
    withRegister: searchWithRegister.value,
  };

  searchVerses(searchParams);
}

function handleFilterByBook(book) {
  setFilter({ bookCode: book });
}

function toHtmlBibleRef(verseModel) {
  const { bookNameCanonLong, chapterNum, verseNum } = verseModel;
  return `${bookNameCanonLong} ${chapterNum}:${verseNum}`;
}

async function openBibleRef(verseModel) {
  loadingRef.value = true;

  const { bookCode, chapterNum } = verseModel;

  await bibles.singleBible.setLocation({ bookCode, chapterNum });

  loadingRef.value = false;

  useEvent("singlebible.openlocation", { location: verseModel });
}
</script>

<template>
  <client-only>
    <div class="app-header px-2">
      <v-btn @click="appVars.appMenu = true" icon="sm" class="icon-size">
        <div class="menu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </v-btn>

      <div class="nav-title">{{ transl("Search") }}</div>

      <v-search-menu
        v-model:withregister="searchWithRegister"
        v-model:wholewords="searchWholeWord"
      >
      </v-search-menu>
    </div>

    <div class="input-panel mx-4 mt-4">
      <v-text-field
        class="input search"
        placeholder="Enter the text"
        clearable
        v-model="searchValue"
        @enter="handleSearch()"
      >
        <template #append>
          <span class="search-icon" @click="handleSearch()"
            ><img src="/icons/search-input.svg" alt=""
          /></span>
        </template>
      </v-text-field>
      <VBibleSelect
        class="ml-2 bible-select-el"
        :singleBible="bibles.singleBible"
        :items="bibles.singleBible?.location?.bibleModel?.booksColl"
      />

      <!-- <v-btn class="search-button shadow" @click="handleSearch()">
      {{ transl("To find") }}
    </v-btn> -->
    </div>

    <div class="mt-4 mx-4">
      <v-segment
        v-model="searchPlace"
        class="bibleplace-segment"
        :items="searchPlaceColl"
        :options="{
          handleColor: '#fff',
          handleBgColor: '#03bfa4',
        }"
        item-label="label"
        item-value="value"
      >
      </v-segment>
    </div>

    <!-- search chips -->
    <div class="chip-panel mx-4 mt-4">
      <v-search-chip
        class="mx-1 my-1"
        v-for="book in resultsBooksColl"
        :book="book"
        :active="filter.bookCode == book.bookCode"
        @select="handleFilterByBook(book.bookCode)"
      >
      </v-search-chip>
    </div>
    <v-loader-ring
      v-if="loading"
      size="100px"
      :color="'var(--root-green)'"
      class="loader"
    ></v-loader-ring>
    <!-- search verses -->
    <div class="results mt-4 mb-10 mx-4" :class="settingsComputedClass">
      <div
        v-for="verse in resultsColl.slice(0, 20)"
        @click.prevent="openBibleRef(verse)"
        class="results-item mb-4"
      >
        <div class="chapter-verse">
          <a class="results-ref"> {{ toHtmlBibleRef(verse) }}</a>
          <div v-html="verse.verseText"></div>
        </div>
      </div>
    </div>
  </client-only>
  <!-- <v-loader v-if="loadingRef" size="50px" center></v-loader> -->
</template>

<style scoped>
.bible-select-el :deep(.select-button) {
  height: 50px;
  line-height: 50px;
}
.bible-select-el :deep(.select-button .more) {
  top: 14px;
}
.input {
  height: 50px;
}
.search-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* background: var(--root-green); */
  border-radius: 50%;
}
.search-icon:hover {
  opacity: 0.8;
}
.search-icon img {
  width: 20px;
  /* filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(335deg) brightness(107%) contrast(101%); */
}
/* .search-icon img:hover {
  transform: scale(1.1);
  transition: 1s;
} */
.shadow {
  box-shadow: 0px 7px 12px 0px rgba(0, 191, 164, 0.25);
}
.results-item {
  /* -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
  background-color: #f3f3f3b3;
  position: relative;
  display: block;
  padding: 5px 20px 15px 20px;
  border-radius: 10px;
}
.results-ref {
  font-size: 18px;
  color: rgb(128, 128, 128);
}

.results-divider {
  content: "";
  width: 100%;
  height: 1px;
  background: #dee3f2;
  margin: 10px 0;
  /* position: absolute; */
  /* top: -10px; */
}
.results-item b {
  font-weight: normal;
  color: var(--root-green);
}
.chip-panel {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* gap: 8px; */
}

/* .segmented-controls :deep() label:last-of-type{
  color: var(--root-white);
} */
.search-button {
  width: 57px;
  height: 44px;
  background: var(--root-green);
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: var(--root-white);
  font-size: 12px;
  margin-left: 10px;
  margin-top: 3px;
  box-sizing: border-box;
}
.v-text-field.input {
  box-shadow: 0px 4px 11px 0px #e1e5f1;
  padding: 0 10px;
}
.v-text-field.input :deep() .input-wrapper {
  padding: 0 10px;
}
.v-text-field.input :deep() input {
  font-size: 16px;
}
.input-panel {
  height: 50px;
  /* display: flex; */
  display: grid;
  grid-template-columns: 60% 40%;
}
/* .input {
  box-shadow: 0px 4px 11px 0px #e1e5f1;
} */
.icon-size {
  position: relative;
  height: 40px;
}

.bibleplace-segment {
  /* font-size: 11px !important; */
}

.segmented-controls :deep() input:checked + label {
  font-size: 11px;
  color: var(--root-white);
  font-weight: 600;
  box-shadow: 0px 7px 12px 0px rgba(0, 191, 164, 0.25);
}

.bibleplace-segment :deep() label {
  font-size: 11px;
  padding: 10px;
  border-radius: 8px;
}

.tab-group .active {
  /* background-color: var(--root-green);; */
  /* color: var(--root-white); */
  box-shadow: 0px 7px 12px 0px rgba(0, 191, 164, 0.25);
}
.tab-group {
  /* display: flex; */
  /* min-height: 48px; */
  /* max-height: 48px; */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 11px 0px #e1e5f1;
  /* align-items: center; */
  background-color: var(--root-white);
}
/* .v-btn {
  width: 100%;
  max-height: 32px;
  border-radius: 8px;
  font-size: 11px;
} */
/* .segmented-controls :deep() label { */
/* font-size: 11px; */
/* padding: 10px; */
/* border-radius: 8px; */
/* } */
/* .segmented-controls :deep() input:checked + label { */
/* font-size: 11px; */
/* color: var(--root-white); */
/* font-weight: 600; */
/* box-shadow: 0px 7px 12px 0px rgba(0, 191, 164, 0.25); */
/* } */
/* .segmented-controls :deep() input:checked + label::after { */
/* background: #00bfa4; */
/* } */

.loader {
  /* align-items: center;
  justify-content: center;
  display: flex;
  color: var(--root-green); */
  /* position: absolute;
  right: -1.6px; */
  margin: 0 auto;
}
</style>

<style scoped>
.transl-item {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
}

.transl-item:hover {
  background: var(--root-green);
  color: var(--root-white);
  cursor: pointer;
}

.search-verse S {
  display: none !important;
}

.search-chips-wrapper {
  padding: 30px 0;
}
.search-chip {
  display: inline-flex;
  /* flex-wrap: wrap; */
  padding: 5px 7px;
  margin: 4px 7px;
  background-color: rgb(211, 222, 234);
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
}

.search-chip:hover {
  background-color: rgb(186, 196, 207);
}

.search-chip:active {
  background-color: rgb(160, 169, 179);
}
</style>
<style scoped>
.app-header {
  width: 100%;
  height: var(--header-height);
  background-color: var(--root-white);
  display: flex;
  align-items: center;
  max-width: var(--width-mobile);
  font-size: 24pt;

  max-width: var(--width-mobile);
  box-sizing: border-box;
  position: sticky;
  top: 0;
  justify-content: space-between;
  flex-shrink: 0;
  z-index: 5;
}
.icon {
  width: 40px;
}
.logo-box {
  display: flex;
}
.icon-size {
  color: #292937;
  width: 40px;
  height: 40px;
}
.icon-size.fix {
  width: 30px;
  height: 100%;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23px;
  height: 15px;
}
.menu span {
  background: #292937;
  height: 3px;
  border-radius: 4px;
  width: 100%;
}

.menu span:nth-child(2n + 1) {
  width: 70%;
  transition: all 0.3s ease;
}
.menu span:nth-child(1) {
  margin-right: auto;
}
.menu span:nth-child(3) {
  margin-left: auto;
}

.menu:hover span:nth-child(2n + 1) {
  width: 100%;
}

.background {
  background-color: #292937;
  border-radius: 8px;
  min-width: 31px;
  min-height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background img {
  width: 11px;
  height: 13px;
}

.logo {
  width: 81px;
  height: 34px;
}
.nav-title {
  color: var(--root-dark);
  font-weight: bold;
  font-size: 21px;
}
</style>
